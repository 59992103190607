import Logo from '../../../Assets/img/Logo/logo.svg';
import WorkflowImg from '../../../Assets/img/General/Workflows.svg';
import Clientes from '../../../Assets/img/General/Clientes.svg';
import Reportes from '../../../Assets/img/General/Reportes.svg';

export const listMenu = [
  {
    name: 'Principal',
    img: Logo,
    alt: 'Integrador Img',
    link: '/',
  },
  {
    name: 'Workflows',
    img: WorkflowImg,
    alt: 'Workflows Img',
    link: '/workflows',
  },
  {
    name: 'Plantillas',
    img: WorkflowImg,
    alt: 'Plantillas Img',
    link: '/templates',
  },
  {
    name: 'Clientes',
    img: Clientes,
    alt: 'Clientes Img',
    link: '/clients',
  },
  {
    name: 'Reportes',
    img: Reportes,
    alt: 'Reportes Img',
    link: '/reports',
  },
];
