import styled, { createGlobalStyle } from 'styled-components';
import DMSansRegular from '../Assets/fonts/DM_Sans/DMSans-Regular.ttf';
import DMSansBold from '../Assets/fonts/DM_Sans/DMSans-Bold.ttf';

import { device } from '../Constants/device';

export const GlobalStyle = createGlobalStyle`
  /* CUSTOM PROPERTIES - VARIABLES
  ============================= */
  :root {
    --primary-color:#FF433F;
    --secondary-color: #fff;

    --black-color: #111111;
    --lightgray-color: #F5F5F5;
    --darkgray-color: #999999;

    --red-color: #D40400;
    --lightpink-color: #FFEBEB;

    --green-color: #22C332;

    --dropshadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    --gray-text: var(--darkgray-color);
    --white-color: #ffffff;
    
    --aside-background: #f2f2f2;
    --page-background: #FBFCFE;

    --input-border-gray: #e6e6e6;
    --disabled-color: #e6e6e6;
    --input-border-red: #ff433f;

    --blue-dark: #1d3c96;
    /* Modal */
    --c-opacity: rgba(17, 17, 17, 0.35);
/* font size responsive 390px - 1240px*/

      
    --fs-small: clamp(0.75rem, calc(0.66rem + 0.35vw), 0.94rem);; // 15px - 12px

    --fs-normal-two: clamp(0.75rem, calc(1.27rem + -0.73vw), 1.13rem); // 18px - 12px
    --fs-normal: clamp(1rem, calc(0.70rem + 0.71vw), 1.25rem);// 20px - 16px

    --fs-medium: clamp(1.25rem, calc(0.96rem + 1.18vw), 1.88rem); //30px - 20px

    --fs-large: clamp(3.13rem, calc(1.69rem + 5.88vw), 6.25rem); //100px - 50px

    /* Image card responsive */


  }

 /* FONTS
  ============================= */
  /* DM SANS */
  @font-face {
    font-family: 'DM Sans';
    src: local('DM Sans'), local('DM Sans'),
    url(${DMSansRegular}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'DM Sans Bold';
    src: local('DM Sans Bold'), local('DM Sans Bold'),
    url(${DMSansBold}) format('truetype');
    font-weight: bold;
    font-style: italic;
  }

/* GLOBALS
  ============================= */
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-size: 16px;
    overflow-x: hidden;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  body {
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    
  }

  figure {
    margin: 0;
  }
  img, video {
    width: 100%;
    height: auto;
  }

  p {
    margin: 0;
    line-height: 1;
  }
  
  h2.title-main {
    font-size: var(--fs-medium);
    font-family: 'DM Sans Bold';
    color: var(--primary-color);
  }

  // Style SCROLL - No aplica firefox
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 20px 20px var(--bg-dark-home);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 13px 6px var(--primary-color);
  }

  /* Alertas */
  .title-alert-dna {
    font-family: 'Press Start 2P';
    font-size: 22px !important;
    color: var(--primary-color);
    padding: 0;
    text-align: center !important;
  }
  .text-alert-dna {
    font-family: 'Century Gothic';
    font-size: 18px !important;
    color: var(--primary-color);
    text-align: center !important;
  }
  .swal2-styled.confirm-alert-dna {
    background: transparent;
    font-family: 'Press Start 2P';
    font-size: 15px !important;
    color: var(--primary-color);
    width: 100%;
    &:focus {
      box-shadow: none;
    }
  }
  .swal2-backdrop-show {
    width: 100% !important;
    background-color: #0000004a !important;
    backdrop-filter: blur(5px);
    & > div {
      max-width: 500px !important;
    }
  }

/* Common */

  .text-center {
    text-align: center;
  }
  @media ${device.tabletL} {
    .title-alert-dna {
      font-size: 20px;
    }
    .text-alert-dna {
      font-size: 16px;
    }
    .swal2-styled.confirm-alert-dna {
      font-size: 14px;
    }
  }
  @media ${device.mobileL} {
    .title-alert-dna {
      font-size: 18px;
    }
    .text-alert-dna {
      font-size: 14px;
    }
    .swal2-styled.confirm-alert-dna {
      font-size: 13px;
    }

    .swal2-backdrop-show {
      & > div {
        width: calc(100vw - 20px) !important;
      }
    }
  }
  /* Responsive */

  .row {
    display: flex;
    flex-wrap: wrap;
  }
  .align-center {
    align-items: center;
  }
  .col, .col-12 {
    flex-basis: 100%;
  }
  .col-10 {
    flex-basis: 83.6%;
  }
  .col-9 {
    flex-basis: 75%;
  }
  .col-8 {
    flex-basis: 66.6%;
  }
  .col-7 {
    flex-basis: 58.33%;
  }
  .col-6 {
    flex-basis: 50%;
  }
  .col-5 {
    flex-basis: 41.66%;
  }
  .col-4 {
    flex-basis: 33.3%;
  }
  .col-3 {
    flex-basis: 25%;
  }
  .col-2 {
    flex-basis: 16.66%;
  }
  .col-1 {
    flex-basis: 16.66%;
  }

  @media ${device.laptop} {
    .col-xl-12 {
      flex-basis: 100%;
    }
    .col-xl-8 {
      flex-basis: 66.6%;
    }
    .col-xl-6 {
      flex-basis: 50%;
    }
    .col-xl-4 {
      flex-basis: 33.3%;
    }
    .col-xl-3 {
      flex-basis: 25%;
    }
    .col-xl-2 {
      flex-basis: 16.66%;
    }
    .col-xl-1 {
      flex-basis: 16.66%;
    }
  }
  @media ${device.tabletL} {
    .col-l-12 {
      flex-basis: 100%;
    }
    .col-l-8 {
      flex-basis: 66.6%;
    }
    .col-l-6 {
      flex-basis: 50%;
    }
    .col-l-4 {
      flex-basis: 33.3%;
    }
    .col-l-3 {
      flex-basis: 25%;
    }
    .col-l-2 {
      flex-basis: 16.66%;
    }
    .col-l-1 {
      flex-basis: 16.66%;
    }
  }
  @media ${device.tablet} {
    .col-m-12 {
      flex-basis: 100%;
    }
    .col-m-8 {
      flex-basis: 66.6%;
    }
    .col-m-6 {
      flex-basis: 50%;
    }
    .col-m-4 {
      flex-basis: 33.3%;
    }
    .col-m-3 {
      flex-basis: 25%;
    }
    .col-m-2 {
      flex-basis: 16.66%;
    }
    .col-m-1 {
      flex-basis: 16.66%;
    }
  }
  @media ${device.mobileL} {
    .col-s-12 {
      flex-basis: 100%;
    }
    .col-s-8 {
      flex-basis: 66.6%;
    }
    .col-s-6 {
      flex-basis: 50%;
    }
    .col-s-4 {
      flex-basis: 33.3%;
    }
    .col-s-3 {
      flex-basis: 25%;
    }
    .col-s-2 {
      flex-basis: 16.66%;
    }
    .col-s-1 {
      flex-basis: 16.66%;
    }
  }
  @media ${device.mobileM} {
    .col-xs-12 {
      flex-basis: 100%;
    }
    .col-xs-8 {
      flex-basis: 66.6%;
    }
    .col-xs-6 {
      flex-basis: 50%;
    }
    .col-xs-4 {
      flex-basis: 33.3%;
    }
    .col-xs-3 {
      flex-basis: 25%;
    }
    .col-xs-2 {
      flex-basis: 16.66%;
    }
    .col-xs-1 {
      flex-basis: 16.66%;
    }
  }
`;

export const PageWrapper = styled.div`
  min-height: calc(100vh);
  width: 100vw;
  background-color: var(--page-background);
  &.active-menu {
    padding-left: 210px;
  }
`;
export const Container = styled.div`
  max-width: 1360px;
  margin: 0 auto;
  position: relative;
  padding: 1rem 3rem;

  @media ${device.laptop} {
    max-width: 1200px;
  }
`;
