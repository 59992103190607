import React, { useEffect, useState } from 'react';

import { StylesWorkflow } from './styles';
import { Button } from '../../Components/Buttons';
import { Input } from '../../Components/Input/InputSecondary';
import { Table } from '../../Components/Table/workflows';
import { useModal } from '../../Hooks/useModal';
import { Modal } from '../../Components/Modals';
import { TemplateCreateWorkflow } from '../../Template/CrearWorkflow';
import Filter from '../../Assets/img/Actions/Filter.svg';
import { useFilterWorkflowMutation} from '../../Store/api/workflows';
import Lupa from '../../Assets/img/Actions/Lupa.svg';
import { DropdownMenuList } from '../../Components/Dropdown/menuList';
import Select from 'react-select';
import { styleTheme } from '../../Styles/SelectTheme';
import { useGetAllClientsQuery } from '../../Store/api/clients';

const optionsTipoWorkflow = [{ value: 'n/a', label: 'Sin datos' }];

const optionsEstado = [
  { value: true, label: 'Activo' },
  { value: false, label: 'Inactivo' },
];

export const Workflow = () => {
  const [isOpenModalCreate, openModalCreate, closeModalCreate] = useModal(false);
  const [dataWorkflow, setDataWorkflow] = useState([]);
  const [searchWorkflow, setSearchWorkflow] = useState('');
  const [dataClient, setDataClient] = useState([]);
  const [selectClients, setSelectClients] = useState([]);
  const [selectStatus, setSelectStatus] = useState(null);

  let { data: getAllClients, isLoading: isLoadingClients, isSuccess: isSuccessClients } = useGetAllClientsQuery();

  let [filterWorkflowFetch, { isLoading: isLoadingFilter, isSuccess: isSuccessFilter, data: getDataFilter }] =
    useFilterWorkflowMutation();

  useEffect(() => {
    filterWorkflowFetch({ term: '' });
  }, [filterWorkflowFetch]);
  useEffect(() => {
    if (isSuccessFilter) {
      setDataWorkflow(getDataFilter.data);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessFilter]);

  useEffect(() => {
    if (isSuccessClients) {
      setDataClient(
        getAllClients.data.map((client) => {
          return {
            value: client.id,
            label: `${client.firstname} ${client.lastname}`,
          };
        })
      );
    }
  }, [isSuccessClients, getAllClients]);

  const inputSearchWorkflow = (e) => {
    let obj = {};
    if (selectClients.length) obj.clients = selectClients;
    if (selectStatus !== null) obj.status = selectStatus;
    if (e.target.value) {
      obj.term = e.target.value;
      filterWorkflowFetch(obj);
      setSearchWorkflow(e.target.value);
    } else {
      obj.term = '';
      setSearchWorkflow('');
      filterWorkflowFetch(obj);
    }
  };
  const handleSearchByFilter = () => {
    let obj = {};
    if (!selectClients.length && !searchWorkflow && selectStatus === null) {
      return filterWorkflowFetch({ term: '' });
    }
    if (selectClients.length) obj.clients = selectClients;
    if (selectStatus !== null) obj.status = selectStatus;
    if (searchWorkflow) obj.term = searchWorkflow;
    filterWorkflowFetch(obj);
  };
  const selectClient = (e) => {
    setSelectClients(
      e.map((client) => {
        return client.value;
      })
    );
  };
  const selectStatusFilter = (e) => {
    setSelectStatus(e ? e.value : null);
  };
  return (
    <StylesWorkflow>
      <h2 className="title-main">Workflows</h2>

      <section className="action-workflow">
        <div className="section-search">
          <div>
            <Input icon={Lupa} type="search" placeholder={'Buscar Workflow...'} onChange={inputSearchWorkflow} />
          </div>

          <DropdownMenuList img={Filter} type="button" allowInternalClick={true}>
            <div className="internal-filters">
              <h5>Tipo de Workflow</h5>
              <div>
                <Select
                  options={optionsTipoWorkflow}
                  isClearable={true}
                  isMulti
                  isSearchable={true}
                  placeholder="Buscar Tipo Workflow"
                  theme={(theme) => styleTheme(theme)}
                />
              </div>
              <h5>Cliente Asociado</h5>
              <div>
                <Select
                  options={dataClient}
                  isMulti
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Buscar Cliente Asociado"
                  isLoading={isLoadingClients}
                  theme={(theme) => styleTheme(theme)}
                  onChange={selectClient}
                />
              </div>
              <h5>Estado de Workflow</h5>
              <div>
                <Select
                  options={optionsEstado}
                  isClearable={true}
                  placeholder="Buscar Estado Workflow"
                  theme={(theme) => styleTheme(theme)}
                  onChange={selectStatusFilter}
                />
              </div>
              <div className="filter-action">
                <Button onPress={handleSearchByFilter}>Buscar</Button>
              </div>
            </div>
          </DropdownMenuList>
        </div>
        <div>
          <Button onPress={() => openModalCreate()}>Crear Workflow</Button>
        </div>
      </section>
      <section>
        <Table
          dataWorkflow={dataWorkflow || []}
          isLoading={isLoadingFilter}
          filterWorkflowFetch={filterWorkflowFetch}
          optionsEstado={optionsEstado}
        />
      </section>
      {/* modal */}

      <Modal
        title="Nuevo Workflow"
        isOpen={isOpenModalCreate}
        closeModal={closeModalCreate}
        size="l"
        closeConfirm={true}
      >
        <TemplateCreateWorkflow
          filterWorkflowFetch={filterWorkflowFetch}
          closeModal={closeModalCreate}
          isOpenModal={isOpenModalCreate}
        />
      </Modal>
    </StylesWorkflow>
  );
};
