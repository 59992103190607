import styled from 'styled-components';
import { device } from '../../Constants/device';

export const StyleTable = styled.section`
  width: 100%;
  margin: 1rem;
  background-color: white;
  border-radius: 1rem;

  & .header-table {
    display: grid;
    grid-template-columns: ${(props) => props.cols};
    color: var(--red-color);
    font-weight: 500;
    border-bottom: 2px solid var(--darkgray-color);
    & > div {
      padding: 0.6rem 1.2rem;
      &:first-child {
        border-radius: 1rem 0 0 0;
      }
      &:last-child {
        border-radius: 0 1rem 0 0;
      }
    }
  }
  & .body-table {
    //max-height: calc(100vh - 300px);
    /* overflow: auto; */
    & .row-table {
      display: grid;
      grid-template-columns: ${(props) => props.cols};
      border-bottom: 1.5px solid var(--darkgray-color);
      border-radius: 1rem;
      &:hover {
        background-color: var(--lightgray-color);
      }
      & > div.col-table {
        padding: 0.6rem 1.2rem;
        display: flex;
        align-items: center;
        & p {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        & .title {
          font-family: 'DM Sans Bold';
          font-size: 1rem;
        }
        & .success {
          color: var(--green-color);
          padding: 0.5rem;
          background-color: #8cff0040;
          border-radius: 0.5rem;
        }

        & .failed {
          color: var(--red-color);
          padding: 0.5rem;
          background-color: #ff000030;
          border-radius: 0.5rem;
        }
        & .details {
          & .detail-text {
            font-size: 0.75rem;
            color: var(--darkgray-color);
          }
          & .line {
            color: var(--darkgray-color);
            padding: 0 0.5rem;
          }
          
        }
        & .section-status {
          display: flex;
          align-items: center;
          gap: 0.6rem;
          & .circle {
            width: 7px;
            height: 7px;
            border-radius: 50%;
            &.active {
              background-color: var(--green-color);
            }
            &.inactive {
              background-color: var(--darkgray-color);
            }
          }
        }
      }
      &:last-child {
        & > div.col-table {
          &:first-child {
            border-radius: 0 0 0 1rem;
          }
          &:last-child {
            border-radius: 0 0 1rem 0;
          }
        }
      }
    }
    & .action-btn {
      display: flex;
      gap: 0.5rem;
      & .sync {
        padding: 8px;
        & img {
          width: 32px;
          transition: transform 0.3s linear;
        }
        &:hover img {
          transform: rotate(90deg);
        }
      }
    }
    & .not-results {
      text-align: center;
      padding: 1rem;
    }
  }
`;
