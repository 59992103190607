import { createSlice } from '@reduxjs/toolkit';

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    isLoadingLogin: false, // Esta cargando login y esperando respuesta
    userLogin: null, // Esta autenticado y almacena datos del token
    failed: null, // Indica que hubo un inconveniente en la autenticacion por ejemplo usuario y/o pass no validos
    nameUser: '',
    email: '',
    idUser: null,
  },
  reducers: {
    startLogin: (state) => {
      state.isLoadingLogin = true;
      state.failed = null;
    },
    authentication: (state, action) => {
      state.isLoadingLogin = false;
      state.failed = null;
      state.userLogin = action.payload.userLogin;
      state.nameUser = action.payload.name;
      state.email = action.payload.email;
      state.idUser = action.payload.id;
    },
    autologin: (state, action) => {
      state.isLoadingLogin = false;
      state.failed = null;
      state.userLogin = action.payload.userLogin;
      state.nameUser = action.payload.name;
      state.email = action.payload.email;
      state.idUser = action.payload.id;
    },
    failed: (state, action) => {
      state.isLoadingLogin = false;
      state.failed = action.payload.message;
    },
    logout: (state) => {
      state.isLoadingLogin = false;
      state.failed = null;
      state.userLogin = null;
      state.nameUser = '';
      state.email = '';
      state.idUser = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { startLogin, authentication, autologin, failed, logout } = loginSlice.actions;
