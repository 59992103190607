import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../Assets/img/Logo/logo.svg';
import User from '../../Assets/img/General/User.svg';
import ConfiguracionImg from '../../Assets/img/General/Configuracion.svg';
import { listMenu } from './data';
import { DropdownMenuList } from '../Dropdown/menuList';
import { logoutUser } from '../../Store/slices/login/thunks';

export const Menu = ({ onPress }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { nameUser, email } = useSelector((state) => state.login);
  let location = useLocation();
  const handleLogout = () => {
    localStorage.removeItem('user');
    navigate('/');
    dispatch(logoutUser());
  };
  return (
    <section>
      <section className="main">
        <aside className="keep">
          <ul>
            <li className="logo-menu" onClick={onPress}>
              <a href="#">
                <div>
                  <img src={Logo} alt="Logo" />
                </div>
                <span className="name-menu">Integrador</span>
              </a>
            </li>
            {listMenu.map((el) => {
              return (
                <li className={location.pathname === el.link ? 'active' : ''} key={`menu-${el.name}`}>
                  <Link to={el.link}>
                    <span>
                      <img src={el.img} alt={el.alt} />
                    </span>
                    <span className="name-menu">{el.name}</span>
                    {/*  <span className="notif red">1</span> */}
                  </Link>
                </li>
              );
            })}
          </ul>
          <ul>
            <li className={location.pathname === '/settings' ? 'active' : ''}>
              <Link to={'/settings'}>
                <span>
                  <img src={ConfiguracionImg} alt="Configuracion Img" />
                </span>
                <span className="name-menu">Configuración</span>
                <span className="notif red">1</span>
                {/* green ,yellow, red */}
              </Link>
            </li>
            <hr className="hr-custom" />
            <li className={location.pathname === '/user' ? 'active' : ''}>
              <a href="#">
                <DropdownMenuList img={User} notification={1} text={nameUser} location="top">
                  <div className="header-dropdown">
                    <div className="img-user">
                      <img src={User} alt="User Img" />
                    </div>
                    <div className="info-user">
                      <div className="name-user">{nameUser}</div>
                      <div className="email-user">{email}</div>
                    </div>
                  </div>
                  <hr style={{ width: '100%' }} />
                  <div className="item">
                    <p>Configuración</p>
                  </div>
                  <div className="item">
                    <p>Ayuda</p>
                  </div>
                  <hr style={{ width: '100%' }} />
                  <div className="item">
                    <p>Politicas</p>
                  </div>
                  <div className="item" onClick={handleLogout}>
                    <p>Cerrar Sesión</p>
                  </div>
                </DropdownMenuList>
              </a>
            </li>
          </ul>
        </aside>
      </section>
    </section>
  );
};
