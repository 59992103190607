import { useState, useEffect } from 'react';
import { ApiRequest } from '../../Utils/ApiRequest';

export const useNodeTypes = () => {
  const [responseNodes, setResponseNodes] = useState([]);
  const [isLoadingNodes, setIsLoadingNodes] = useState(false);
  const [errorNodes, setErrorNodes] = useState(null);

  useEffect(() => {
    const getNodesN8N = async () => {
      try {
        setIsLoadingNodes(true);
        setResponseNodes([]);
        setErrorNodes(null);

        const {data} = await ApiRequest.get('/nodes');

        setResponseNodes(data.map(el =>  ({ value: el.name, label: el.displayName })));
      } catch (error) {
        setErrorNodes(error);
      } finally {
        setIsLoadingNodes(false);
      }
    };
    getNodesN8N();
  }, []);

  return { isLoadingNodes, responseNodes, errorNodes };
};
