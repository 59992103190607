import { useState, useEffect } from 'react';
import { ApiRequest } from '../../Utils/ApiRequest';

export const useCredentialsTypes = () => {
  const [responseCredentials, setResponseCredentials] = useState([]);
  const [isLoadingCredentials, setIsLoadingCredentials] = useState(false);
  const [errorCredentials, setErrorCredentials] = useState(null);

  useEffect(() => {
    const getCredentialsN8N = async () => {
      try {
        setIsLoadingCredentials(true);
        setResponseCredentials([]);
        setErrorCredentials(null);

        const {data} = await ApiRequest.get('/credentials');

        setResponseCredentials(data.map(el =>  ({ value: el.name, label: el.displayName })));
      } catch (error) {
        setErrorCredentials(error);
      } finally {
        setIsLoadingCredentials(false);
      }
    };
    getCredentialsN8N();
  }, []);

  return { isLoadingCredentials, responseCredentials, errorCredentials };
};
