import styled from 'styled-components';

export const StyleButton = styled.button`
  background-color: ${(props) => props.bgColor || 'var(--primary-color)'};
  color: ${(props) => props.color || 'white'};
  border: 1px solid ${(props) => props.borderColor || 'transparent'};
  border-radius: 16px;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  cursor: pointer;
  padding: 12px 24px;
  letter-spacing: 1px;
  text-align: center;
  font-size: ${(props) => props.fontSize || 'var(--fs-small)'};
  font-weight: bold;
  outline: none;
  position: relative;
  display: inline-block;
  min-width: ${(props) => (props.minWidth || '150px')};
  width: ${(props) => (props.widthFull ? '100%' : null)};
  transition: background-color 0.5s linear, color 0.5s linear, border 0.5s linear, transform 0.5s linear,
    border 0.5s linear;
  &:hover {
    transform: scale(1.03);
    background-color: ${(props) => props.bgColorHover || 'var(--red-color)'};
  }
  &:active {
    transform: scale(0.95);
  }
  &:disabled {
    cursor: not-allowed;

    background-color: var(--disabled-color);
  }
  &:focus {
    outline: none;
  }
  &.ghost {
    background-color: transparent;
    border-color: #ffffff;
  }
`;
