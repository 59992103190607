import { useState, useEffect } from 'react';
import Select from 'react-select';
import { Container } from '../../Styles/globalStyles';
import { styleTheme } from '../../Styles/SelectTheme';
import { SectionPagination, StylePagination } from './styles';

/**
 * PaginationMain: Componente que permite la paginación de los datos
 *   @data = es la data o informacion que vamos a paginar
 *   @setDataPagination = es el seteo de los datos que se va a ver en la paginacion.
 *   @numberItems = permite saber cuantos items o datos se van a ver por pagina por defecto 20 datos
 *
 * */
export const PaginationMain = ({ data = [], setDataPagination }) => {
  const [pageActive, setPageActive] = useState(null);
  const [numberItems, setNumberItems] = useState(25);

  useEffect(() => {
    if (!data) return;
    getData(1);
  }, [data, numberItems]);

  const getPages = () => {
    if (data) {
      const last = Math.ceil(data.length / numberItems);
      let active = parseInt(pageActive);

      let pages = [];
      let numberPage = active < 11 ? 1 : active - 10;
      for (let i = 1; i <= 20; i++) {
        if (numberPage <= last) {
          pages.push(
            <div
              className={`item ${numberPage === active ? 'active' : ''}`}
              id={numberPage}
              onClick={(e) => handlePagination('number', e)}
              key={numberPage}
            >
              {numberPage}
            </div>
          );
          numberPage++;
        }
      }
      return pages;
    }
  };

  const handlePagination = (name, e = null) => {
    if (name === 'number') {
      getData(Number(e.target.id));
    }
    if (name === 'first') {
      getData(1);
    } else if (name === 'prev') {
      if (pageActive - 1 >= 1) {
        getData(pageActive - 1);
      }
    } else if (name === 'next') {
      if (pageActive + 1 <= Math.ceil(data.length / numberItems)) {
        getData(pageActive + 1);
      }
    } else if (name === 'last') {
      getData(Math.ceil(data.length / numberItems));
    }
  };

  const getData = (numberPage) => {
    const arr = [];
    const start = (numberPage - 1) * numberItems;
    const end = start + numberItems;
    for (let idx = start; idx < end; idx++) {
      arr.push(data[idx]);
    }
    const filterArr = arr.filter((el) => el !== undefined);
    if (data.length === 0) {
      setDataPagination([]);
    } else {
      setDataPagination(filterArr);
    }

    setPageActive(numberPage);
  };

  const selectPerPage = (e) => {
    setNumberItems(e.value);
  };

  return (
    <Container>
      {data?.length > 0 && (
        <SectionPagination>
          <StylePagination>
            <div className="pagination">
              <div className="item first" onClick={() => handlePagination('first')}>
                {'<<'}
              </div>
              <div className="item prev" onClick={() => handlePagination('prev')}>
                {'<'}
              </div>
              {getPages()}
              <div className="item next" onClick={() => handlePagination('next')}>
                {'>'}
              </div>
              <div className="item last" onClick={() => handlePagination('last')}>
                {'>>'}
              </div>
            </div>
          </StylePagination>
          <Select
            options={[
              {
                label: '10',
                value: 10,
              },
              {
                label: '25',
                value: 25,
              },
              {
                label: '50',
                value: 50,
              },
            ]}
            theme={(theme) => styleTheme(theme)}
            defaultValue={{
              label: '25',
              value: 25,
            }}
            onChange={selectPerPage}
          />
        </SectionPagination>
      )}
    </Container>
  );
};
